.hero {
  position: relative;
  width: 100%;
  min-height: 600px;
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: radial-gradient(circle 1300px at 65% 35%, rgba(241, 122, 59, 0.91) 0%, rgba(249, 224, 196, 1) 100%);

  @media screen and (max-width: 768px) {
    justify-content: flex-start;
  }

  @media screen and (max-width: 650px) {
    justify-content: center;
  }

  @media screen and (max-width: 350px) {
    min-height: 450px;
  }
}

.hero__img {
  position: absolute;
  max-width: 350px;
  bottom: 0;
  left: 65%;
  z-index: 0;
  transform: translateX(-50%);

  @media screen and (max-width: 650px) {
    left: unset;
    transform: translateX(0);
  }
}

.hero__info {
  z-index: 1;
  transform: translateX(-100px);

  @media screen and (max-width: 768px) {
    transform: translateX(0);
    margin-left: 4rem;
  }

  @media screen and (max-width: 650px) {
    transform: translateY(50%);
    margin-left: 0;
  }
}

.hero__title {
  text-shadow: 2px 2px 0 #FFFFFF;
  margin-bottom: 1rem;

  @media screen and (max-width: 890px) {
    font-size: var(--font-2xl);
  }

  @media screen and (max-width: 650px) {
    font-size: var(--font-2xl);
    color: var(--white);
    text-shadow: 3px 3px 0 var(--black);
  }

  @media screen and (max-width: 350px) {
    font-size: var(--font-xl);
  }
}

.hero__subtitle {
  font-size: var(--font-xl);

  @media screen and (max-width: 890px) {
    font-size: var(--font-md);
  }

  @media screen and (max-width: 768px) {
    font-size: var(--font-md);
  }

  @media screen and (max-width: 650px) {
    color: var(--white);
  }
}

.hero__social {
  position: absolute;
  left: 3.5rem;
  top: 50%;
  display: flex;
  flex-direction: column;
  row-gap: .5rem;

  &::before,
  &::after {
    position: absolute;
    left: 50%;
    display: block;
    content: '';
    width: 1px;
    height: 150px;
    background-color: var(--gray);
    transform: translateX(-50%);

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  &::before {
    top: -160px;
  }

  &::after {
    bottom: -160px;
  }

  @media screen and (max-width: 768px) {
    left: unset;
    top: unset;
    right: 2rem;
    bottom: 1rem;
  }

  @media screen and (max-width: 350px) {
    right: 1rem;
  }
}

.hero__social-link {
  display: flex;
  text-decoration: none;
  font-size: 1.3rem;
  color: var(--gray);
  cursor: pointer;

  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 480px) {
    padding: .2rem;
    border-radius: .2rem;
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(5px);
  }
}

.hero__birds-animation {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}