.about {
  max-width: 900px;
  padding: 3.5rem 1.5rem;
  margin: 0 auto;
}

.about__wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 3.5rem;
  align-items: center;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
  }
}

.about__img {
  width: 100%;
}

.about__img-wrapper{
  position: relative;
  width: 180px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: -15px;
    bottom: -15px;
    width: 80%;
    height: 80%;
    border: 2px solid var(--gray);
    border-right: none;
    border-top: none;
  }

  @media screen and (min-width: 768px) {
    flex: 0 0 180px;
  }
}

.about__text {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  p {
    font-size: 18px;
  }

  a {
    text-decoration: underline;
    color: var(--gray);
  }
}

.about__title {
  text-align: center;
  margin-bottom: 2rem;
}

.about__skills {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 2.5rem;
  max-width: 750px;
  margin: 0 auto;
}

.about__skill {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  row-gap: 1rem;
  transition: transform .3s;

  &:hover {
    transform: scale(1.1);
    
    .about__skill-img {
      box-shadow: 0 0 10px #00000044;
    }

    .about__skill-title {
      transform: translateY(-5px);
    }
  }
}

.about__skill-img {
  width: 100px;
  height: 100px;
  padding: 1.2rem;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: box-shadow .3s;

  img {
    max-height: 100%;
  }
}

.about__skill-title {
  font-weight: bold;
  transition: transform .3s;
  transform: translateY(0);
}