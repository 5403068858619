.page404__section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  padding: 7rem 2rem;
}

.page404__title {
  text-align: center;
}

.page404__text {
  font-size: var(--font-xl);
}

.page404__link {
  background-color: var(--main-color);
  color: var(--white);
  font-size: var(--font-lg);
  padding: 1rem 2rem;
  border-radius: 2px;
  font-weight: bold;

  transition: background-color .3s;

  &:hover {
    background-color: var(--main-color-alt);
  }

  &:active {
    background-color: var(--main-color);
  }

  @media screen and (max-width: 399px) {
    font-size: 0.9rem;
    padding: 0.8rem 1rem;
  }
}
