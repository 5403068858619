.contact {
  max-width: 900px;
  padding: 3.5rem 1.5rem;
  margin: 0 auto;
}

.contact__wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem 1rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.contact__block--1 {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 318px;
  margin: 0 auto;

  @media screen and (max-width: 400px) {
    width: 100%;
  }

  .contact__block-img--1 {
    width: 70%;
  }

  .contact__block-img--2 {
    position: absolute;
    display: inline-block;
    right: 0;
    top: 50%;
    width: 180px;
    height: 180px;
    transform: translateY(-50%) scale(-1, 1);

    @media screen and (max-width: 300px) {
      width: 130px;
      height: 130px;
    }

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      right: -15px;
      bottom: -15px;
      width: 80%;
      height: 80%;
      border: 2px solid var(--gray);
      border-left: none;
      border-top: none;
    }
  }
}

.contact__form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 1rem;

  @media screen and (max-width: 768px) {
    align-items: center;
  }

  label {
    width: 85%;

    @media screen and (max-width: 400px) {
      width: 100%;
    }

    div {
      font-size: var(--font-sm);
      margin-top: .5rem;
      color: #B22222;

      &::before{
        content: '* ';
      }
    }
  }

  input,
  textarea {
    padding: .5rem .8rem;
    width: 100%;
    border: 2px solid var(--gray);

    &:focus,
    &:focus-visible {
      border: 2px solid var(--main-color);
    }
  }

  textarea {
    height: 7rem;
    resize: none;
    overflow: auto;
  }

  button {
    width: 85%;
    padding: .5em .8rem;
    border: none;
    outline: none;
    font: inherit;
    font-weight: 500;
    letter-spacing: .2em;
    background-color: var(--gray);
    color: var(--white);
    transition: background-color .3s, color .3s;

    @media screen and (max-width: 400px) {
      width: 100%;
    }

    &:hover {
      background-color: var(--main-color);
      color: var(--gray);
    }

    &:active {
      background-color: var(--main-color-alt);
      color: var(--gray);
    }

    &:disabled {
      pointer-events: none;
      opacity: .4;
    }
  }

  &--success {
    text-align: center;
  }

  &--error {
    text-align: center;
    color: var(--main-color);
  }
}

.contact__block--3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.5rem;

  h3 {
    font-size: var(--font-lg);
  }
}

.contact__link-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-start;
  align-items: flex-start;
}

.contact__link {
  color: var(--black);
  display: flex;
  align-items: center;
  flex-direction: row;
  -webkit-column-gap: .5rem;
  -moz-column-gap: .5rem;
  column-gap: .5rem;
  cursor: pointer;
  transition: color .3s;

  &:hover {
    color: var(--main-color);
  }

  &:active {
    color: var(--main-color-alt);
  }
}

.contact__social-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
}

.contact__social-link {
  color: var(--black);
  font-size: var(--font-xl);
  transition: color .3s;

  &:hover {
    color: var(--main-color);
  }

  &:active {
    color: var(--main-color-alt);
  }
}

.contact__block--4 {
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    right: -15px;
    bottom: -15px;
    width: 70%;
    height: 70%;
    border: 2px solid var(--gray);
    border-left: none;
    border-top: none;
  }
}
