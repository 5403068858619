.section-header {
  margin-bottom: 2rem;

  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
}

.app {
  min-height: 100vh;
}