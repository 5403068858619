.portfolio-item {
  padding: 1rem;
  border: 1px solid var(--light-gray);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;

  transition: box-shadow .3s;
  
  &:hover {
    box-shadow: 3px 3px 15px #00000044;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
  }
}

.portfolio-item__img {
  overflow: hidden;
  margin-bottom: 1rem;

  @media screen and (min-width: 768px) {
    flex: 0 0 30%;
  }
}

.portfolio-item__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
  width: 100%;
}

.portfolio-item__title {
  font-size: 2rem;
}

.portfolio-item__description {
  margin-bottom: 1.5rem;

  p {
    margin-bottom: .5rem;
  }

  @media screen and (min-width: 768px) {
    font-size: var(--font-md);
  }
}

.portfolio-item__tags {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: .5rem;
  margin-bottom: 1rem;
}

.portfolio-item__tag {
  display: flex;
  flex-direction: row;
  background-color: var(--light-gray);
  padding: .5rem .8rem;
  border-radius: .5rem;
  border: none;
  font-weight: bold;
  font-size: var(--font-sm);
  gap: .3rem;

  transition: transform .3s, background-color .3s;
  
  &:hover {
    transform: translateY(-.2rem);
    background-color: var(--main-color);
  }

  &:focus-visible {
    background-color: var(--main-color);
    box-shadow: 0 0 0 2px var(--black);
    transform: translateY(-.2rem);

    &::after {
      display: none;
    }
  }
}

.portfolio-item__links {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.portfolio-item__link {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .4rem;
  color: var(--black);
  font-weight: bold;
  font-size: 1.2rem;
  transition: color .3s;

  .ri-github-fill {
    font-size: 2.2rem;
  }

  &:hover {
    color: var(--main-color);
  }

  &:active {
    color: var(--main-color-alt);
  }

  &:not([href]) {
    display: none;
  }

  @media screen and (min-width: 768px) {
    font-size: var(--font-md);

    .ri-github-fill {
      font-size: 2rem;
    }
  }
}