.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 4rem;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 3.5rem;
  background-color: transparent;
  transition: background-color .3s, box-shadow .3s;
  z-index: 100;

  &--scroll {
    background-color: var(--white);
    box-shadow: 0 0 10px #54545455;
  }

  @media screen and (max-width: 768px) {
    padding: 0 2rem;
  }

  @media screen and (max-width: 300px) {
    padding: 0 1rem;
  }
}

.navbar__logo {
  font-family: 'Montserrat Alternates', sans-serif;
  font-size: var(--font-xl);
  text-decoration: none;
  color: var(--black);

  @media screen and (max-width: 300px) {
    font-size: var(--font-md);
  }
}

.navbar__links {
  display: flex;
  flex-direction: row;
  column-gap: 3rem;

  @media screen and (max-width: 890px) {
    display: none;
  }
}

.navbar__link {
  position: relative;
  text-decoration: none;
  color: var(--black);
  font-family: 'Montserrat Alternates', sans-serif;
  font-weight: 500;

  &::before {
    content: '';
    height: 2px;
    width: 0;
    background-color: var(--gray);
    position: absolute;
    left: 0;
    bottom: -4px;
    transition: width .3s;
  }

  &:hover::before {
    width: 1.5rem;
  }

  &--active::before {
    width: 1.5rem;
  }
}

.navbar__toggle {
  font-size: 1.5rem;
  line-height: 1.5rem;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;

  @media screen and (min-width: 891px) {
    display: none;
  }
}