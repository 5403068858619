@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@500;600;700&family=Raleway:wght@400;500&display=swap');

:root {
  --white: #FFFFFF;
  --light-gray: #CCCCCC;
  --gray: #161616;
  --black: #121212;
  --main-color: rgb(241, 122, 59);
  --main-color-alt: rgb(192, 91, 38);

  --font-3xl: 4rem;
  --font-2xl: 2.5rem;
  --font-xl: 1.5rem;
  --font-lg: 1.25rem;
  --font-md: 1rem;
  --font-sm: .85rem;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Raleway", sans-serif;
  min-height: 100vh;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  font-family: "Montserrat Alternates", sans-serif;
  user-select: none;
}

h1 {font-size: var(--font-3xl);}
h2 {font-size: var(--font-2xl);}
h3 {font-size: var(--font-xl);}
h4 {font-size: var(--font-lg);}
h5 {font-size: var(--font-md);}
h6 {font-size: var(--font-sm);}

p {
  font-size: var(--font-md);
  user-select: none;
}

span {
  user-select: none;
}

ul, ol {
  list-style: none;
}

a {
  text-decoration: none;
}

a:focus-visible,
button:focus-visible {
  position: relative;
  outline: none;

  &::after {
    position: absolute;
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    content: '';
    border-radius: 3px;
    box-shadow: 0 0 0 2px var(--main-color), 0 0 0 4px var(--white);
  }
}

img {
  display: block;
  width: 100%;
  height: auto;
}

input,
textarea {
  font: inherit;
  outline: none;

  :focus,
  :focus-visible {
    border: 2px solid var(--main-color);
  }
}