.footer__contact-me-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  row-gap: 2rem;
  position: relative;
  width: 100%;
  height: 300px;
  padding: 2rem;
  margin-bottom: -1px;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    column-gap: 2rem;
  }
}

.footer__contact-me-img {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: brightness(0.3);
  object-fit: cover;
  object-position: center;
}

.footer__contact-me-text {
  max-width: 400px;
  font: 600 2rem 'Montserrat Alternates', sans-serif;
  color: var(--white);
  text-align: center;

  @media screen and (max-width: 399px) {
    font-size: 1.4rem;
  }
}

.footer__contact-me-link {
  background-color: var(--white);
  color: var(--black);
  font-size: var(--font-lg);
  padding: 1rem 2rem;
  border-radius: 2px;
  font-weight: bold;

  transition: background-color .3s;

  &:hover {
    background-color: var(--main-color);
  }

  &:active {
    background-color: var(--main-color-alt);
  }

  @media screen and (max-width: 399px) {
    font-size: 0.9rem;
    padding: 0.8rem 1rem;
  }
}

.footer__main {
  background-color: var(--gray);
  color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2.5rem;
  padding: 2.5rem 1rem;
}

.footer__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  row-gap: 2rem;
  width: 100%;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
  }
}

.footer__contacts {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: flex-start;
}

.footer__contact-links {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: flex-start;
}

.footer__contact-link {
  color: var(--white);
  font-size: var(--font-md);
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 1rem;
  cursor: pointer;
  transition: color .3s;

  &:hover {
    color: var(--main-color);
  }

  &:active {
    color: var(--main-color-alt);
  }
}

.footer__social {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: center;
}

.footer__social-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 2rem;
}

.footer__social-link {
  color: var(--white);
  font-size: var(--font-xl);
  transition: color .3s;

  &:hover {
    color: var(--main-color);
  }

  &:active {
    color: var(--main-color-alt);
  }
}