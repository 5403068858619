.portfolio {
  max-width: 900px;
  padding: 3.5rem 1.5rem;
  margin: 0 auto;
}

.portfolio__loader {
  width: 100%;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 2rem;
  text-align: center;
}

.portfolio__tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: .5rem;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.portfolio__tag {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  background-color: var(--white);
  padding: .5rem .8rem;
  border: 1px solid var(--light-gray);
  border-radius: .5rem;
  font-weight: bold;
  gap: .3rem;
  outline: none;

  transition: background-color .3s, border-color .3s;

  &:hover {
    background-color: var(--main-color);
    border-color: var(--main-color);
  }

  &:focus-visible {
    background-color: var(--main-color);
    border-color: var(--main-color);
    box-shadow: 0 0 0 2px var(--black);

    &::after {
      display: none;
    }
  }

  &:active {
    background-color: var(--main-color-alt);
    border-color: var(--main-color-alt);
  }

  &--active {
    background-color: var(--main-color);
    border-color: var(--main-color);
  }

  @media screen and (min-width: 768px) {
    font-size: var(--font-sm);
  }
}

.portfolio__items {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}