.mobile-menu {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: 4.5rem 1rem 3rem;
  background-color: var(--white);
  animation: move-in .5s ease-in-out;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 90;
}

@keyframes move-in {
  from {
    top: -100%;
  }
  to {
    top: 0;
  }
}

.mobile-menu__links {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
}

.mobile-menu__link {
  font-size: var(--font-xl);
  font-weight: 500;
  color: var(--gray);

  &:hover {
    color: var(--black);
  }
}