.to-top {
  position: fixed;
  right: 4rem;
  bottom: 2rem;
  background-color: var(--light-gray);
  border: 2px solid transparent;
  outline: none;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 0 5px #55555588;

  font-size: 1.5rem;
  line-height: 1.5rem;

  transition: background-color .3s, border-color .3s;

  &:hover {
    background-color: rgb(241, 122, 59);
  }

  &:active {
    background-color: rgb(192, 91, 38);
  }

  &:focus-visible {
    position: fixed;
    border: 2px solid var(--main-color-alt);
    background-color: rgb(241, 122, 59);

    &::after {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    right: 2rem;
  }

  @media screen and (max-width: 350px) {
    right: 1rem;
  }
}